import { css } from '@emotion/react'
import { Divider, Paper } from '@mui/material'
import { memo } from 'react'
import useMedia from '~/hooks/useMedia'
import { globalGrey } from '~/modules/AppLayout/Colors'
import SelfSelectSymbols from '~/modules/realtimeDashboard/SelfSelectedSymbols'
import { SignalrQuote } from '~/modules/SDK/Signalr/SignalrQuote'
import Pivots from '~/modules/strength-state/Pivots'
import { usePivotState } from '~/modules/strength-state/usePivotState'
import { huanan_store } from '~/pages/huanan_template/_private/huanan_store'
import { CollapseContent } from '~/pages/futuresai/_private/components/CollapseContent'
import { StocksList } from '~/pages/huanan_template/_private/StocksList'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import Col_Gauge from '~/pages/heineken_template/_col/col_Gauge'

export const DefaultsInformation = memo<ReactProps>(function DefaultsInformation(props) {
  const { isPc } = useMedia()

  return (
    <Paper
      className={props.className}
      css={css`
        border-right: 1px solid ${globalGrey.g400};
        padding: 1px;
        overflow-y: scroll;
        ${isPc && 'overflow-x: hidden;'}
        ${isPc && 'height: 100vh;'}
      `}
    >
      <ChartingServerSwitch charting={huanan_store.charting}></ChartingServerSwitch>

      <CollapseContent
        localStorageKey='drawers.gauge'
        displayTitle='大戶能量'
      >
        <Col_Gauge.Display dataSource='dashboard_bs_indicator' />
        <Col_Gauge.Display dataSource='dashboard_trade_info' />
      </CollapseContent>

      {/* <Divider /> */}

      {/* <CollapseContent
        localStorageKey='drawers.pivot'
        displayTitle='盤勢轉折'
      >
        <usePivotState.Provider>
          <Pivots />
        </usePivotState.Provider>
      </CollapseContent> */}

      <Divider />

      <CollapseContent
        localStorageKey='drawers.selfSelected-list'
        displayTitle='自選股'
      >
        <SelfSelectSymbols
          selfSelectSymbolGroupName={'futuresai_stock_group_1'}
          activateTool={function Symbol(_props) {
            return (
              <SignalrQuote
                symbol={_props.symbol}
                onClick={event => {
                  huanan_store.charting.changeSymbol(_props.symbol)
                }}
              ></SignalrQuote>
            )
          }}
        />
      </CollapseContent>

      {/* <Divider /> */}

      {/* <CollapseContent
        localStorageKey='drawers.stocks-list'
        displayTitle='台股報價'
      >
        <StocksList
          charting={store.charting}
          symbolStrings={[
            'TSEA',
            'OTCA',
            '2330',
            '2317',
            '2454',
            '2881',
            '2882',
            '2303',
            '2002',
            '2603',
            '2609',
            '2615',
          ]}
        />
      </CollapseContent> */}

      <Divider />

      <CollapseContent
        localStorageKey='drawers.futures-list'
        displayTitle='期貨報價'
      >
        <StocksList
          charting={huanan_store.charting}
          symbolStrings={[
            'TX-1',
            'TWN-1',
            'YM-1',
            'NQ-1',
            'ES-1',
            'RTY-1',
            'FDX-1',
            'NK-1',
            'HSI-1',
            'CN-1',
            'CL-1',
            'GC-1',
            'DX-1',
          ]}
        />
      </CollapseContent>
    </Paper>
  )
})
